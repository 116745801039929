/* eslint-disable import/prefer-default-export */
import { IDR } from '@/libs/currency'
import { DAY_MONTH_YEAR } from '@/libs/filterDate'

export const configColumn = [
  {
    key: 'seller_name',
    label: 'Seller',
    class: 'text-black',
    thStyle: {
      background: '#FCD4BE',
      borderBottom: 0,
      minWidth: '200px',
      fontWeight: 'bold',
      fontSize: '14px',
      textTransform: 'capitalize',
    },
  },
  {
    key: 'cnote',
    label: 'Resi',
    class: 'text-black',
    thStyle: {
      background: '#FCD4BE',
      borderBottom: 0,
      fontWeight: 'bold',
      fontSize: '14px',
      textTransform: 'capitalize',
    },
  },
  {
    key: 'date_retur',
    label: 'Diputuskan Retur',
    class: 'text-black',
    thStyle: {
      background: '#FCD4BE',
      borderBottom: 0,
      minWidth: '200px',
      fontWeight: 'bold',
      fontSize: '14px',
      textTransform: 'capitalize',
    },
    tdClass: 'text-black',
    formatter: value => DAY_MONTH_YEAR(value),
  },
  {
    key: 'last_update_date',
    label: 'Last Update',
    class: 'text-black',
    thStyle: {
      background: '#FCD4BE',
      borderBottom: 0,
      minWidth: '200px',
      fontWeight: 'bold',
      fontSize: '14px',
      textTransform: 'capitalize',
    },
    tdClass: 'text-black',
    formatter: value => DAY_MONTH_YEAR(value),
  },
  {
    key: 'transaction_value',
    label: 'Nilai Transaksi',
    class: 'text-black',
    thStyle: {
      background: '#FCD4BE',
      borderBottom: 0,
      minWidth: '200px',
      fontWeight: 'bold',
      fontSize: '14px',
      textTransform: 'capitalize',
    },
    tdClass: 'text-black',
    formatter: value => IDR(value),
  },
  {
    key: 'action',
    label: 'Detail',
    class: 'text-black',

    thStyle: {
      background: '#FCD4BE',
      borderBottom: 0,
      minWidth: '200px',
      fontWeight: 'bold',
      fontSize: '14px',
      textTransform: 'capitalize',
    },
  },
  {
    key: 'status_retur',
    label: 'Status Retur',
    class: 'text-black',
    thStyle: {
      background: '#FCD4BE',
      borderBottom: 0,
      fontWeight: 'bold',
      fontSize: '14px',
      textTransform: 'capitalize',
    },
  },
  {
    key: 'action_history',
    label: 'Riwayat Action',
    class: 'text-black',
    thStyle: {
      background: '#FCD4BE',
      borderBottom: 0,
      fontWeight: 'bold',
      fontSize: '14px',
      textTransform: 'capitalize',
    },
  },
]

export const option = [
  {
    title: 'Semua',
    value: '',
  },
  {
    title: 'Perlu Diproses',
    value: 0,
  },
  {
    title: 'Sedang Diproses',
    value: 1,
  },
]

export const optionPriority = [
  {
    title: 'Priority',
    value: '',
  },
  {
    title: 'Juragan',
    value: 1,
  },
  {
    title: 'Middle',
    value: 2,
  },
]
