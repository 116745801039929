<template>
  <b-card id="wrapper">
    <b-row class="mb-2">
      <b-col cols="3">
        <h3 class="text-black">
          <strong>
            Pengembalian Paket
          </strong>
        </h3>
      </b-col>
      <b-col cols="3">
        <v-select
          v-model="statusPriority"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="title"
          :reduce="item => item.value"
          :options="optionPriority"
          :clearable="false"
          @input="searchLogRetur"
        />
      </b-col>
      <b-col cols="3">
        <v-select
          v-model="status"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="title"
          :reduce="item => item.value"
          :options="option"
          :clearable="false"
          @input="searchLogRetur"
        />
      </b-col>
      <b-col cols="3">
        <b-input-group class="input-group-merge">
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-prepend>
          <b-form-input
            v-model="search"
            placeholder="Cari seller dan no. resi"
            @input="searchLogRetur"
          />
        </b-input-group>
      </b-col>
    </b-row>

    <!-- Data Table -->
    <b-overlay
      variant="light"
      :show="loading"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
    >
      <b-row>
        <b-table
          :fields="fields"
          :items="items"
          responsive
          show-empty
          empty-text="Tidak ada data ditampilkan."
          style="min-height: 320px!important;"
        >
          <template #cell(seller_name)="data">
            <div
              v-if="data.item.is_juragan === 1"
              class="flex bg-[#10673E] rounded-3xl justify-center"
              style="padding: 2px 0px"
            >
              <img
                :src="data.item.juragan_image_path"
                alt="Komerce"
              >
              <span class="text-white ml-[10px]">Juragan</span>
            </div>
            <span class="text-black">{{ data.value }}</span>
          </template>

          <template #cell(cnote)="data">
            <b-row
              class="align-items-center"
              style="min-width: 250px!important;"
            >
              <b-img
                class="mr-50"
                :src="data.item.shipping_image_path"
                width="50"
              />
              <span class="text-black mr-50">{{ data.value }}</span>
              <b-img
                class="cursor-pointer btn btn-flat-dark btn-icon p-0"
                src="@/assets/images/icons/copy.svg"
                @click="copyCnote(data.value)"
              />
            </b-row>
          </template>

          <template #cell(action)="data">
            <b-button
              variant="flat-info"
              class="btn-icon"
              @click="handleDetail(data.item.order_id)"
            >
              Lihat Detail
            </b-button>
          </template>

          <template #cell(status_retur)="data">
            <div
              class="px-2 d-flex justify-content-center align-items-center"
              :style="`min-width: 180px!important;
            border: 1px solid ${getStyleStatusRetur(data.value)};
            border-radius: 10px;`"
            >
              <span :class="`text-[${getStyleStatusRetur(data.value)}]`">{{ getStatusRetur(data.value) }}</span>
            </div>
          </template>

          <template #cell(action_history)="data">
            <div
              v-if="data.item.status_follow_up === 0"
              class="d-flex align-items-center"
              style="min-width: 100px!important;"
            >
              <b-dropdown
                id="dropdown-1"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                text="Perlu Diproses"
                variant="dark"
                left
              >
                <b-dropdown-item @click="confirmUpdateStatus(data)">
                  Sedang Diproses
                </b-dropdown-item>
              </b-dropdown>
            </div>
            <div
              v-if="data.item.status_follow_up === 1"
            >
              <div
                :id="`log_retur_updated_at-${data.index}`"
                class="px-2 d-flex justify-content-center align-items-center"
                style="min-width: 180px!important;
            border: 1px solid #FBA63C;
            border-radius: 10px;"
              >
                <span class="text-[#FBA63C]">Sedang Diproses</span>
              </div>
              <b-popover
                triggers="hover"
                :target="`log_retur_updated_at-${data.index}`"
                placement="bottomleft"
              >{{ moment(new Date(data.item.log_retur_upated_at)).format('DD MMMM YYYY') }}</b-popover>
            </div>
          </template>
        </b-table>
      </b-row>
    </b-overlay>

    <!-- Popup Confirmation -->
    <b-modal
      ref="popup-confirmation"
      hide-footer
      hide-header
      centered
      no-close-on-backdrop
      no-close-on-esc
    >
      <b-row class="justify-content-center mt-2 mb-3">
        <b-img src="@/assets/images/icons/warning.svg" />
      </b-row>

      <b-row class="text-black justify-content-center mb-4">
        <b-col cols="10">
          <span>Paket ini stuck lebih dari 5 hari, untuk mengubah
            status<br>
            kamu perlu memastikan :
          </span>
          <br>
          <div class="ml-1">
            <ul style="list-style: decimal;">
              <li>
                Paket di-follow up terlebih dahulu<br>
                (konfirmasikan ke ops ekspedisi)
              </li>
              <li>
                Pastikan paket benar-benar ditangani
              </li>
            </ul>
          </div>
        </b-col>
      </b-row>

      <b-row class="mb-2 justify-content-center">
        <b-col cols="5">
          <b-button
            variant="outline-primary"
            block
            @click="closePopupConfirmation"
          >
            Batal
          </b-button>
        </b-col>
        <b-col cols="5">
          <b-button
            class="px-0"
            variant="primary"
            block
            @click="updateStatusRetur"
          >
            Ya, Sudah Dilakukan
            <b-spinner
              v-if="loadingUpdateStatus"
              small
              class="ml-50"
            />
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </b-card>
</template>

<script>
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { configColumn, option, optionPriority } from './config'

export default {
  components: {
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
      option,
      optionPriority,
      selectedActionHistory: { title: 'Perlu Diproses' },
      optionActionHistory: [{ title: 'Semua' }, { title: 'Perlu Diproses' }, { title: 'Sedang Diproses' }],

      //   Data Table
      fields: configColumn,
      items: [],
      moment,
      loadingUpdateStatus: false,
      statusReturValue: 'Perlu Diproses',
      dataLogRetur: {},
      search: '',
      status: { title: 'Semua', value: '' },
      statusPriority: { title: 'Priority', value: '' },
      limit: 50,
      offset: 0,
      isLastOrder: false,
    }
  },
  created() {
    window.onscroll = () => {
      if ((window.innerHeight + window.scrollY) >= document.getElementById('wrapper').offsetHeight && !this.loading) {
        this.infiniteScroll()
      }
    }
  },
  mounted() {
    this.getLogRetur()
  },
  methods: {
    getLogRetur() {
      this.loading = true
      this.$http_komship.get(`/v1/ticket-mitra/list/package-return?limit=${this.limit}&offset=${this.offset}`)
        .then(response => {
          const { data } = response.data
          this.items = data.log_returns
          this.loading = false
          this.offset = data.log_returns.length
          if (data.log_returns.length < this.limit) {
            this.isLastOrder = true
          } else {
            this.isLastOrder = false
          }
        }).catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err,
              variant: 'danger',
            },
          }, 2000)
          this.loading = false
        })
    },
    handleDetail(data) {
      this.$router.push({
        path: `/data-order/detail-order/${data}`,
      })
    },
    formatPrice(value) {
      const val = value
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    getStatusRetur(data) {
      let result
      if (data === 0) {
        result = 'Belum Retur'
      } else if (data === 1) {
        result = 'Perjalanan Pulang'
      } else if (data === 2) {
        result = 'Paket Diterima'
      } else if (data === 3) {
        result = 'Paket Hilang'
      } else if (data === 4) {
        result = 'Paket Rusak'
      }
      return result
    },
    getStyleStatusRetur(data) {
      let result
      if (data === 0) {
        result = '#FBBC05'
      } else if (data === 1) {
        result = 'black'
      } else if (data === 2) {
        result = '#007F00'
      } else if (data === 3) {
        result = '#E31A1A'
      } else if (data === 4) {
        result = '#E31A1A'
      }
      return result
    },
    confirmUpdateStatus(data) {
      this.$refs['popup-confirmation'].show()
      this.dataLogRetur = data
    },
    updateStatusRetur() {
      this.loadingUpdateStatus = true
      const formData = new FormData()
      formData.append('status_follow_up', 1)
      this.$http_komship.post(`/v1/ticket-mitra/update/status-follow-up/${this.dataLogRetur.item.log_retur_id}`, formData)
        .then(async () => {
          this.offset = 0
          this.limit = 50
          await this.getLogRetur()
          this.$refs['popup-confirmation'].hide()
          this.loadingUpdateStatus = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckCircleIcon',
              text: 'Berhasil Mengubah Status Riwayat Action',
              variant: 'success',
            },
          }, 1000)
        }).catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err,
              variant: 'danger',
            },
          }, 1000)
          this.loadingUpdateStatus = false
        })
    },
    closePopupConfirmation() {
      this.$refs['popup-confirmation'].hide()
    },
    searchLogRetur: _.debounce(function () {
      if (this.status !== null) {
        this.loading = true
        this.$http_komship.get(`/v1/ticket-mitra/list/package-return?search=${this.search}&status=${this.status.value !== undefined ? this.status.value : this.status}&priority=${this.statusPriority.value !== undefined ? this.statusPriority.value : this.statusPriority}`)
          .then(response => {
            const { data } = response.data
            this.items = data.log_returns
            this.loading = false
          }).catch(err => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Failure',
                icon: 'AlertCircleIcon',
                text: err,
                variant: 'danger',
              },
            }, 2000)
            this.loading = false
          })
      } else {
        this.loading = true
        this.$http_komship.get(`/v1/ticket-mitra/list/package-return?search=${this.search}`)
          .then(response => {
            const { data } = response.data
            this.items = data
            this.loading = false
          }).catch(err => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Failure',
                icon: 'AlertCircleIcon',
                text: err,
                variant: 'success',
              },
            }, 2000)
            this.loading = false
          })
      }
      this.$forceUpdate()
    }, 1000),
    copyCnote(data) {
      navigator.clipboard.writeText(data)
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `Resi ${data} berhasil dicopy`,
          icon: 'AlertCircleIcon',
          variant: 'warning',
        },
      }, 1000)
    },
    infiniteScroll() {
      if (!this.isLastOrder) {
        this.loading = true
        const params = this.$http_komship.get(`/v1/ticket-mitra/list/package-return?limit=${this.limit}&offset=${this.offset}&status=${this.status.value !== undefined ? this.status.value : this.status}&priority=${this.statusPriority.value !== undefined ? this.statusPriority.value : this.statusPriority}`)
          .then(response => {
            const { data } = response.data
            this.items.push(...data.log_returns)
            this.offset += data.log_returns.length
            this.loading = false
            if (data.log_returns.length < this.limit) {
              this.isLastOrder = true
            }
          }).catch(err => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Failure',
                icon: 'AlertCircleIcon',
                text: err,
                variant: 'danger',
              },
            }, 2000)
            this.loading = false
          })
      }
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
